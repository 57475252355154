import React, { useEffect, useState } from "react";
import type { IconBaseProps, IconName } from "@purpurds/icon";
import { DEFAULT_SIZE } from "@purpurds/icon";
import { pascalCase } from "change-case";

export type B2xIconDynamicProps = {
  name: IconName;
} & IconBaseProps;

export const B2xIconDynamic = ({
  ["data-testid"]: dataTestId,
  name,
  className,
  allyTitle,
  size = DEFAULT_SIZE,
  ...props
}: B2xIconDynamicProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [Component, setComponent] = useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    const importComponent = async () => {
      try {
        const module = await import(`@purpurds/icon/dist/components/${name}.es.js`);
        setComponent(() => module[`Icon${pascalCase(name)}`]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error importing component "${name}":`, error);
      }
    };

    importComponent();
  }, [name]);

  return Component ? (
    <Component
      allyTitle={allyTitle}
      className={className}
      size={size}
      data-testid={dataTestId}
      {...props}
    />
  ) : null;
};

B2xIconDynamic.displayName = "IconDynamic";
